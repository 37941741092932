import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../components/styles/seance.css';
import Layout from '../components/layout';
import HeaderRandom from '../components/headerRandom';
import Seo from '../components/seo';

const notify = (message) => {
  return toast(message, {
    position: 'bottom-left',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });
};
const CardiacCoherence = () => {
  const handleSumbit = async (e) => {
    e.preventDefault();
    const { currentTarget: target } = e;
    const payload = new FormData(target);
    const data = await fetch('https://fauna-workers.bowada.workers.dev/mail', { method: 'POST', body: payload });
    if (data.ok) {
      notify('Le message a été transmis');
      return target.reset();
    }
    notify('Une erreur a eu lieu.');
  };
  return (
    <Layout>
      <Seo />
      <HeaderRandom />
      <div id='title'>
        <h1>Contactez-moi</h1>
      </div>

      <div className='division'>
        <div className='container tarifs'>
          <div className='row'>
            <div className='col-md-8 col-sm-12 col-xs-12 adressinfo jump-mobile'>
              <div className='col-md-3 col-sm-12 col-xs-12 center-mobile'>
                <p><i className='fas fa-map-marker' aria-hidden='true' /> Lieu : </p>
                <p className='center'><b>9 rue du quatre septembre,<br /> 75002 Paris,<br /> France.</b></p>
                <i>À proximité immédiate du Métro Bourse/Quatre Septembre</i>
                <p className='center'>Ainsi qu'au:<br /> <b>107 allée des peupliers,<br /> 95620 Parmain,<br /> France.</b></p>
                <hr className='mobile' />
              </div>
              <div className='col-md-3 col-sm-12 col-xs-12 center-mobile'>
                <p><i className='fas fa-clock' /> Horaires : </p>
                <p className='center'><b>Lundi au vendredi de 9h à 18h <br />Samedi de 14h à 18h</b>
                </p>
                <hr className='mobile' />
              </div>
              <div className='col-md-3 col-sm-12 col-xs-12 center-mobile'>
                <p><i className='fas fa-envelope' /> Mail : </p>
                <p className='center'><b>francine.lampin@hypnose-venture.fr</b></p>
                <hr className='mobile' />
              </div>
              <div className='col-md-3 col-sm-12 col-xs-12 center-mobile'>
                <p><i className='fas fa-phone' /> Téléphone : </p>
                <p className='center'><b><a href='tel:06.60.24.18.84'>06.60.24.18.84</a></b></p>
                <hr className='mobile' />
              </div>
            </div>
            <div className='col-md-4 col-sm-12 col-xs-12'>
              <form id='contact' onSubmit={handleSumbit}>
                <input type='text' name='nom' placeholder='Nom...' required />
                <input type='tel' name='tel' placeholder='Téléphone...' pattern='^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$' required />
                <input type='email' name='mail' placeholder='Mail...' required />
                <textarea name='message' cols='30' rows='10' placeholder='Message...' required />
                <button type='submit' className='blue'>Envoyer</button>
              </form>
              <br />
            </div>
            <div className='col-md-4 col-sm-12 col-xs-12'>
              <a href='https://www.doctolib.fr/hypnotherapeute/paris/francine-lampin' rel='noreferrer noopener'>
                <p>Prendre un rendez-vous:</p>
                <StaticImage src='../img/event.png' placeholder='blurred' alt='diplome' width={64} height={64} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer theme='dark' />
    </Layout>
  )
  ;
};

export default CardiacCoherence;
