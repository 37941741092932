import React from 'react';

const HeaderRandom = () => {
  const random = ~~(Math.random() * 4) + 1;
  return (
    <div className={`headerRandom select-${random}`} />
  );
};

export default HeaderRandom;
